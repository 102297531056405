<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>Tree</h5>
                <Tree :value="treeValue" 
                selectionMode="single" 
                v-model:selectionKeys="selectedTreeValue" 
                :metaKeySelection="false" 
                @node-select="onNodeSelect" @node-unselect="onNodeUnselect"></Tree>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <h5>TreeTable</h5>
                <TreeTable :value="treeTableValue" selectionMode="checkbox" v-model:selectionKeys="selectedTreeTableValue">
                    <template #header>
                        FileSystem
                    </template>
                    <Column field="name" header="Name" :expander="true"></Column>
                    <Column field="size" header="Size"></Column>
                    <Column field="type" header="Type"></Column>
                </TreeTable>
            </div>
        </div>
    </div>
</template>

<script>
    import NodeService from '../service/NodeService'

    export default {
        data() {
            return {
                treeValue: null,
                selectedTreeValue: null,
                treeTableValue: null,
                selectedTreeTableValue: null,
            }
        },
        nodeService: null,
        created() {
            this.nodeService = new NodeService();
        },
        mounted() {
            this.nodeService.getTreeNodes().then(data => this.treeValue = data);
            this.nodeService.getTreeTableNodes().then(data => this.treeTableValue = data);
        },
        methods: {
            onNodeSelect(node) {
                console.log(node.label);
                this.$toast.add({severity:'success', summary: 'Node Selected', detail: node.label, life: 3000});
            },
            onNodeUnselect(node) {
                this.$toast.add({severity:'success', summary: 'Node Unselected', detail: node.label, life: 3000});
            }
        }
    }
</script>

<style scoped>

</style>
