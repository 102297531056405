import axios from 'axios';

export default class NodeService {

    getTreeTableNodes() {
        return axios.get('http://10.38.0.5:8080/assets/demo/data/treetablenodes.json')
                .then(res => res.data.root);
    }

    getTreeNodes() {
        return axios.get('http://10.38.0.5:8080/assets/demo/data/treenodes.json')
                .then(res => res.data.root);
    }

}
